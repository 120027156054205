import { useEffect } from "react";

const { kakao } = window;

const KakaoMap = ({ data }) => {
  useEffect(() => {
    var mapContainer = document.getElementById(data.id),
      mapOption = {
        center: new kakao.maps.LatLng(35.0160601, 126.7107572),
        level: 3,
      };

    var map = new kakao.maps.Map(mapContainer, mapOption);

    var geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(data.address1, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        // eslint-disable-next-line no-unused-vars
        var marker = new kakao.maps.Marker({
          map: map,
          position: coords,
        });

        map.setCenter(coords);
      }
    });
  }, [data]);

  return <div id={data.id} style={{ width: "100%", height: "300px" }}></div>;
};

export default KakaoMap;
