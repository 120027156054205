export const OutData = [
  {
    id: 1,
    dong: "남평읍",
    name: "(유)일등에너지",
    address: "전라남도 나주시 남평읍 영산로 6685",
    post: 58223,
    x: 126.841363845286,
    y: 35.0615963525756,
  },
  {
    id: 2,
    dong: "남평읍",
    name: "건우하우징랜드(주)",
    address: "전라남도 나주시 남평읍 회재로 12",
    post: 58223,
    x: 126.838590005348,
    y: 35.0510636551263,
  },
  {
    id: 3,
    dong: "남평읍",
    name: "남평농협 파머스마켓 강변점",
    address: "전라남도 나주시 남평읍 강변2길 5-3",
    post: 58227,
    x: 126.850867609373,
    y: 35.0399991185311,
  },
  {
    id: 4,
    dong: "남평읍",
    name: "남평농협자재센터",
    address: "전라남도 나주시 남평읍 남평1로 29",
    post: 58227,
    x: 126.841036696514,
    y: 35.0474689574512,
  },
  {
    id: 5,
    dong: "남평읍",
    name: "남평농협파머스마켓",
    address: "전라남도 나주시 남평읍 남평1로 29",
    post: 58227,
    x: 126.841036696514,
    y: 35.0474689574512,
  },
  {
    id: 6,
    dong: "남평읍",
    name: "진성 미곡처리장",
    address: "전라남도 나주시 남평읍 등수평야길 244-3",
    post: 58228,
    x: 126.83034568073,
    y: 35.029339066497,
  },
  {
    id: 7,
    dong: "남평읍",
    name: "행복주유소",
    address: "전라남도 나주시 남평읍 영산로 6688",
    post: 58223,
    x: 126.842232156215,
    y: 35.0621822958786,
  },
  {
    id: 8,
    dong: "세지면",
    name: "세지농협 하나로마트",
    address: "전라남도 나주시 세지면 동창로 130-2",
    post: 58318,
    x: 126.749025936436,
    y: 34.9196211705683,
  },
  {
    id: 9,
    dong: "세지면",
    name: "세지농협주유소",
    address: "전라남도 나주시 세지면 죽동길 5-29",
    post: 58305,
    x: 126.71227283822,
    y: 34.9385674199447,
  },
  {
    id: 10,
    dong: "세지면",
    name: "세지예향주유소",
    address: "전라남도 나주시 세지면 예향로 3014",
    post: 58316,
    x: 126.708634849058,
    y: 34.9275935280875,
  },
  {
    id: 11,
    dong: "세지면",
    name: "주식회사 이레가축약품",
    address: "전라남도 나주시 세지면 신동길 13",
    post: 58316,
    x: 126.707324535493,
    y: 34.9244248762226,
  },
  {
    id: 12,
    dong: "왕곡면",
    name: "(유)안전에너지주유소일봉지점",
    address: "전라남도 나주시 왕곡면 여시물길 70",
    post: 58296,
    x: 126.670078278723,
    y: 34.9616851766246,
  },
  {
    id: 13,
    dong: "왕곡면",
    name: "국제농기계",
    address: "전라남도 나주시 왕곡면 불로길 5",
    post: 58275,
    x: 126.703869467985,
    y: 34.9874277030006,
  },
  {
    id: 14,
    dong: "왕곡면",
    name: "나주축협축산기술지원단",
    address: "전라남도 나주시 왕곡면 영산포로 4",
    post: 58276,
    x: 126.70591004808,
    y: 34.9828636497463,
  },
  {
    id: 15,
    dong: "왕곡면",
    name: "농업회사법인 효진바이오뱅크",
    address: "전라남도 나주시 왕곡면 나주서부로 4",
    post: 58275,
    x: 126.70344732704,
    y: 34.9855523403741,
  },
  {
    id: 16,
    dong: "왕곡면",
    name: "마한농업협동조합 양산지점",
    address: "전라남도 나주시 왕곡면 장산양산길 37",
    post: 58304,
    x: 126.705655954323,
    y: 34.9733470476573,
  },
  {
    id: 17,
    dong: "왕곡면",
    name: "마한농업협동조합 왕곡지점",
    address: "전라남도 나주시 왕곡면 나주서부로 372",
    post: 58296,
    x: 126.671967786949,
    y: 34.96997869563,
  },
  {
    id: 18,
    dong: "왕곡면",
    name: "마한농협주유소 양산점",
    address: "전라남도 나주시 왕곡면 예향로 3531",
    post: 58304,
    x: 126.705937514809,
    y: 34.9726987337609,
  },
  {
    id: 19,
    dong: "왕곡면",
    name: "영산포농협 가야지점",
    address: "전라남도 나주시 왕곡면 영산포로 22",
    post: 58276,
    x: 126.706211333805,
    y: 34.9835085065898,
  },
  {
    id: 20,
    dong: "왕곡면",
    name: "유한회사 산들애",
    address: "전라남도 나주시 왕곡면 영산포로 30-17",
    post: 58276,
    x: 126.70650544789,
    y: 34.9853356876084,
  },
  {
    id: 21,
    dong: "왕곡면",
    name: "효진가축약품(주)",
    address: "전라남도 나주시 왕곡면 나주서부로 4",
    post: 58275,
    x: 126.70344732704,
    y: 34.9855523403741,
  },
  {
    id: 22,
    dong: "반남면",
    name: "마한농협주유소",
    address: "전라남도 나주시 반남면 고분로 604",
    post: 58302,
    x: 126.65236097562,
    y: 34.9028524733257,
  },
  {
    id: 23,
    dong: "반남면",
    name: "마한농협하나로마트",
    address: "전라남도 나주시 반남면 고분로 604",
    post: 58302,
    x: 126.65236097562,
    y: 34.9028524733257,
  },
  {
    id: 24,
    dong: "반남면",
    name: "스톤마트주식회사",
    address: "전라남도 나주시 반남면 장송길 54-45",
    post: 58298,
    x: 126.656926549717,
    y: 34.9426972998233,
  },
  {
    id: 25,
    dong: "공산면",
    name: "공산농협 주유소",
    address: "전라남도 나주시 공산면 공산로 142",
    post: 58290,
    x: 126.606568207907,
    y: 34.9429640625241,
  },
  {
    id: 26,
    dong: "공산면",
    name: "공산농협자재센터",
    address: "전라남도 나주시 공산면 공산로 130",
    post: 58290,
    x: 126.608003802647,
    y: 34.9428151074027,
  },
  {
    id: 27,
    dong: "공산면",
    name: "공산농협하나로마트",
    address: "전라남도 나주시 공산면 공산로 138",
    post: 58290,
    x: 126.607039639202,
    y: 34.9429955143512,
  },
  {
    id: 28,
    dong: "동강면",
    name: "나주시농협쌀조합공동사업법인",
    address: "전라남도 나주시 동강면 동강로 637",
    post: 58292,
    x: 126.569864771353,
    y: 34.9392805379607,
  },
  {
    id: 29,
    dong: "동강면",
    name: "동강농협 하나로마트",
    address: "전라남도 나주시 동강면 동강로 585",
    post: 58292,
    x: 126.563969467636,
    y: 34.936931248323,
  },
  {
    id: 30,
    dong: "동강면",
    name: "동강농협구매사업장",
    address: "전라남도 나주시 동강면 동강로 585",
    post: 58292,
    x: 126.563969467636,
    y: 34.936931248323,
  },
  {
    id: 31,
    dong: "다시면",
    name: "남도주유소",
    address: "전라남도 나주시 다시면 영산로 4875",
    post: 58279,
    x: 126.672365520766,
    y: 35.0153268185926,
  },
  {
    id: 32,
    dong: "다시면",
    name: "다시농협 주유소",
    address: "전라남도 나주시 다시면 다시로 175-14",
    post: 58203,
    x: 126.637061100355,
    y: 35.018538213368,
  },
  {
    id: 33,
    dong: "다시면",
    name: "다시농협 하나로마트",
    address: "전라남도 나주시 다시면 다시로 177",
    post: 58203,
    x: 126.637309753262,
    y: 35.0177836048216,
  },
  {
    id: 34,
    dong: "다시면",
    name: "다시농협조합 자재과",
    address: "전라남도 나주시 다시면 다시로 175-14",
    post: 58203,
    x: 126.637061100355,
    y: 35.018538213368,
  },
  {
    id: 35,
    dong: "문평면",
    name: "(주)나주가스",
    address: "전라남도 나주시 문평면 다시로 86",
    post: 58286,
    x: 126.628328437989,
    y: 35.021212751932,
  },
  {
    id: 36,
    dong: "문평면",
    name: "다시농협문평지점 구매자재과",
    address: "전라남도 나주시 문평면 체암로 303",
    post: 58204,
    x: 126.602689332275,
    y: 35.0544287776481,
  },
  {
    id: 37,
    dong: "문평면",
    name: "다시농협문평지점 하나로마트",
    address: "전라남도 나주시 문평면 체암로 299",
    post: 58204,
    x: 126.603558287934,
    y: 35.0548064993916,
  },
  {
    id: 38,
    dong: "노안면",
    name: "노안농업협동조합",
    address: "전라남도 나주시 노안면 금산로 23",
    post: 58209,
    x: 126.730509535104,
    y: 35.0758801520466,
  },
  {
    id: 39,
    dong: "노안면",
    name: "노안농협 하나로마트",
    address: "전라남도 나주시 노안면 금산로 12",
    post: 58209,
    x: 126.731522999151,
    y: 35.0755382571622,
  },
  {
    id: 40,
    dong: "노안면",
    name: "노안농협학산지점",
    address: "전라남도 나주시 노안면 학산길 1",
    post: 58208,
    x: 126.749294666891,
    y: 35.0640068497463,
  },
  {
    id: 41,
    dong: "노안면",
    name: "아이에스오일 주식회사",
    address: "전라남도 나주시 노안면 오정길 358",
    post: 58205,
    x: 126.690937687875,
    y: 35.0989011160748,
  },
  {
    id: 42,
    dong: "노안면",
    name: "여주농산영농조합법인",
    address: "전라남도 나주시 노안면 금산로 18",
    post: 58209,
    x: 126.731833217249,
    y: 35.0762979861447,
  },
  {
    id: 43,
    dong: "노안면",
    name: "전남건설기계운전학원",
    address: "전라남도 나주시 노안면 금산로 76",
    post: 58209,
    x: 126.728755965609,
    y: 35.0800640004691,
  },
  {
    id: 44,
    dong: "노안면",
    name: "주식회사 대원노안제일주유소",
    address: "전라남도 나주시 노안면 건재로 683",
    post: 58208,
    x: 126.761012271816,
    y: 35.0761982808347,
  },
  {
    id: 45,
    dong: "금천면",
    name: "(주)금구주유소",
    address: "전라남도 나주시 금천면 영산로 5848",
    post: 58215,
    x: 126.767934591002,
    y: 35.034580294096,
  },
  {
    id: 46,
    dong: "금천면",
    name: "(주)남현주유소",
    address: "전라남도 나주시 금천면 빛가람장성로 2055",
    post: 58211,
    x: 126.775932475148,
    y: 35.0451506998415,
  },
  {
    id: 47,
    dong: "금천면",
    name: "(주)신도시주유소",
    address: "전라남도 나주시 금천면 빛가람장성로 2045",
    post: 58211,
    x: 126.776347112897,
    y: 35.0445442089289,
  },
  {
    id: 48,
    dong: "금천면",
    name: "금천농협 남부지점(자재판매장)",
    address: "전라남도 나주시 금천면 광암1길 8",
    post: 58220,
    x: 126.765172255415,
    y: 35.0142445965907,
  },
  {
    id: 49,
    dong: "금천면",
    name: "금천농협 (자재과,연쇄점)",
    address: "전라남도 나주시 금천면 금영로 975",
    post: 58215,
    x: 126.753343131094,
    y: 35.0341099272206,
  },
  {
    id: 50,
    dong: "금천면",
    name: "금천농협주유소",
    address: "전라남도 나주시 금천면 영산로 5845",
    post: 58211,
    x: 126.767056350188,
    y: 35.0348872423628,
  },
  {
    id: 51,
    dong: "금천면",
    name: "나주현대서비스 주식회사",
    address: "전라남도 나주시 금천면 영산로 5851",
    post: 58211,
    x: 126.767911439671,
    y: 35.0352104015932,
  },
  {
    id: 52,
    dong: "금천면",
    name: "빛가람주유소",
    address: "전라남도 나주시 금천면 영산로 5680",
    post: 58215,
    x: 126.75004404323,
    y: 35.0349867945762,
  },
  {
    id: 53,
    dong: "금천면",
    name: "유한회사 신도시주유소",
    address: "전라남도 나주시 금천면 빛가람장성로 2045",
    post: 58211,
    x: 126.776347112897,
    y: 35.0445442089289,
  },
  {
    id: 54,
    dong: "금천면",
    name: "이서방혁신점",
    address: "전라남도 나주시 금천면 영산로 5703",
    post: 58211,
    x: 126.752543224683,
    y: 35.0355710505982,
  },
  {
    id: 55,
    dong: "산포면",
    name: "나주시공공급식지원센터",
    address: "전라남도 나주시 산포면 영산로 6187-8",
    post: 58212,
    x: 126.804816551908,
    y: 35.0416883061772,
  },
  {
    id: 56,
    dong: "산포면",
    name: "나주혁신주유소",
    address: "전라남도 나주시 산포면 영산로 6022",
    post: 58214,
    x: 126.786063331417,
    y: 35.0389226377126,
  },
  {
    id: 57,
    dong: "산포면",
    name: "녹색한우조합 공동사업법인",
    address: "전라남도 나주시 산포면 영산로 6041",
    post: 58211,
    x: 126.787415977538,
    y: 35.0400127048807,
  },
  {
    id: 58,
    dong: "산포면",
    name: "산포농업협동조합",
    address: "전라남도 나주시 산포면 산포로 421",
    post: 58214,
    x: 126.803007150638,
    y: 35.0353568217338,
  },
  {
    id: 59,
    dong: "산포면",
    name: "산포농협 육묘사업소",
    address: "전라남도 나주시 산포면 산포로 421",
    post: 58214,
    x: 126.803007150638,
    y: 35.0353568217338,
  },
  {
    id: 60,
    dong: "산포면",
    name: "산포농협덕례지소",
    address: "전라남도 나주시 산포면 등정덕례길 282",
    post: 58222,
    x: 126.797246360117,
    y: 35.0542839565646,
  },
  {
    id: 61,
    dong: "다도면",
    name: "남평농협다도지점(하나로마트,자재센터)",
    address: "전라남도 나주시 다도면 다도로 791",
    post: 58319,
    x: 126.827070321092,
    y: 34.9442595664176,
  },
  {
    id: 62,
    dong: "봉황면",
    name: "봉황농협 농기구 서비스센터",
    address: "전라남도 나주시 봉황면 봉황로 708",
    post: 58307,
    x: 126.779784854396,
    y: 34.9557541782976,
  },
  {
    id: 63,
    dong: "봉황면",
    name: "봉황농협 하나로마트, 자재판매장",
    address: "전라남도 나주시 봉황면 봉황로 715",
    post: 58307,
    x: 126.780900551146,
    y: 34.9573382214029,
  },
  {
    id: 64,
    dong: "봉황면",
    name: "봉황농협 하나로마트(지점)",
    address: "전라남도 나주시 봉황면 봉황로 719-7",
    post: 58307,
    x: 126.779746451213,
    y: 34.9573406410254,
  },
  {
    id: 65,
    dong: "봉황면",
    name: "봉황농협덕림지점 하나로마트",
    address: "전라남도 나주시 봉황면 세남로 158-7",
    post: 58314,
    x: 126.761775114429,
    y: 34.9274230524206,
  },
  {
    id: 66,
    dong: "봉황면",
    name: "봉황농협주유소",
    address: "전라남도 나주시 봉황면 봉황로 715",
    post: 58307,
    x: 126.780900551146,
    y: 34.9573382214029,
  },
  {
    id: 67,
    dong: "송월동",
    name: "(주)광주식자재나주점",
    address: "전라남도 나주시 나주로 65 (송월동)",
    post: 58259,
    x: 126.715733874615,
    y: 35.0247966660647,
  },
  {
    id: 68,
    dong: "송월동",
    name: "나주농협 송월지점",
    address: "전라남도 나주시 빛가람로 29(송월동)",
    post: 58261,
    x: 126.717566153869,
    y: 35.0167172184106,
  },
  {
    id: 69,
    dong: "송월동",
    name: "송월주유소",
    address: "전라남도 나주시 예향로 4191(송월동)",
    post: 58263,
    x: 126.711011675029,
    y: 35.0269535919246,
  },
  {
    id: 70,
    dong: "송월동",
    name: "윤정헤어",
    address: "전라남도 나주시 남고문로 36-14(송월동, 송월주공아파트)",
    post: 58259,
    x: 126.714510440439,
    y: 35.0279227396074,
  },
  {
    id: 71,
    dong: "송월동",
    name: "한성티앤아이",
    address: "전라남도 나주시 늦은맛재길 25(송월동)",
    post: 58263,
    x: 126.708302794217,
    y: 35.0269497248747,
  },
  {
    id: 72,
    dong: "금남동",
    name: "나주곰탕(하얀집)",
    address: "전라남도 나주시 금성관길 6-1 (중앙동)",
    post: 58257,
    x: 126.717232981821,
    y: 35.0322423746715,
  },
  {
    id: 73,
    dong: "금남동",
    name: "나주농협하나로마트",
    address: "전라남도 나주시 나주로 101(금성동)",
    post: 58259,
    x: 126.717096931717,
    y: 35.027964671644,
  },
  {
    id: 74,
    dong: "금남동",
    name: "나주배원예농협(자재센터)",
    address: "전라남도 나주시 청동길 32(삼도동)",
    post: 58252,
    x: 126.724695880209,
    y: 35.0379942614359,
  },
  {
    id: 75,
    dong: "금남동",
    name: "콤스 영농조합법인",
    address: "전라남도 나주시 청동길 31 (삼도동)",
    post: 58252,
    x: 126.724354910737,
    y: 35.0383278070222,
  },
  {
    id: 76,
    dong: "성북동",
    name: "나주농협송현지점(하나로마트,자재판매장)",
    address: "전라남도 나주시 건재로 205(대호동)",
    post: 58242,
    x: 126.722687728568,
    y: 35.0509882114694,
  },
  {
    id: 77,
    dong: "성북동",
    name: "나주비뇨기과의원",
    address: "전라남도 나주시 영산로 5419(성북동), 신관3층",
    post: 58251,
    x: 126.721561690885,
    y: 35.0367447456518,
  },
  {
    id: 78,
    dong: "성북동",
    name: "나주온누리약국",
    address: "전라남도 나주시 영산로 5422 (성북동)",
    post: 58255,
    x: 126.721464845407,
    y: 35.0355398364573,
  },
  {
    id: 79,
    dong: "성북동",
    name: "나주축협하나로마트",
    address: "전라남도 나주시 대호길 19(성북동)",
    post: 58249,
    x: 126.717695443332,
    y: 35.0376569264479,
  },
  {
    id: 80,
    dong: "성북동",
    name: "㈜대원동신주유소",
    address: "전라남도 나주시 건재로 89(대호동)",
    post: 58246,
    x: 126.715305719664,
    y: 35.0429472737418,
  },
  {
    id: 81,
    dong: "영산동",
    name: "영산홍어(주)",
    address: "전라남도 나주시 영산포로 199-5 (영산동)",
    post: 58266,
    x: 126.709778435978,
    y: 34.9991141272363,
  },
  {
    id: 82,
    dong: "이창동",
    name: "(유)안전에너지주유소",
    address: "전라남도 나주시 나주서부로 97(동수동)",
    post: 58277,
    x: 126.694197118726,
    y: 34.9838351588475,
  },
  {
    id: 83,
    dong: "이창동",
    name: "금호종합상사",
    address: "전라남도 나주시 이창1길 40(이창동)",
    post: 58267,
    x: 126.710526157517,
    y: 34.9968770653545,
  },
  {
    id: 84,
    dong: "이창동",
    name: "나주축협하나로마트영산포점",
    address: "전라남도 나주시 남교1길 6-4 (이창동)",
    post: 58270,
    x: 126.715073461571,
    y: 34.9957309917088,
  },
  {
    id: 85,
    dong: "이창동",
    name: "대동공업 나주대리점",
    address: "전라남도 나주시 풍물시장1길 10-21 (이창동)",
    post: 58274,
    x: 126.710710265616,
    y: 34.9943942920282,
  },
  {
    id: 86,
    dong: "이창동",
    name: "대천농기계나주점",
    address: "전라남도 나주시 풍물시장2길 24-13(이창동)",
    post: 58274,
    x: 126.709118448236,
    y: 34.9924892067599,
  },
  {
    id: 87,
    dong: "이창동",
    name: "영산포 한우 암소직판장",
    address: "전라남도 나주시 예향로 3781 (이창동)",
    post: 58274,
    x: 126.71041787522,
    y: 34.9931308511156,
  },
  {
    id: 88,
    dong: "이창동",
    name: "영산포농협 하나로마트",
    address: "전라남도 나주시 예향로 3775 (이창동)",
    post: 58274,
    x: 126.709756665801,
    y: 34.9930250725905,
  },
  {
    id: 89,
    dong: "이창동",
    name: "주식회사 정석푸드나주",
    address: "전라남도 나주시 동수농공단지길 62-54(운곡동)",
    post: 58275,
    x: 126.687739206345,
    y: 34.9895460061874,
  },
  {
    id: 90,
    dong: "이창동",
    name: "주식회사 해농",
    address: "전라남도 나주시 동수농공단지길 30-91(운곡동)",
    post: 58275,
    x: 126.688899002718,
    y: 34.9882730794104,
  },
  {
    id: 91,
    dong: "이창동",
    name: "청록수산",
    address: "전라남도 나주시 풍물시장2길 24-3(이창동)",
    post: 58274,
    x: 126.708219767489,
    y: 34.9930965637686,
  },
  {
    id: 92,
    dong: "빛가람동",
    name: "(주)와이마트 나주혁신중흥점",
    address: "전라남도 나주시 상야2길 7 (빛가람동)",
    post: 58217,
    x: 126.788855145087,
    y: 35.0229460279127,
  },
  {
    id: 93,
    dong: "빛가람동",
    name: "(주)유진텍코퍼레이션(CGV) 나주점",
    address: "전라남도 나주시 상야4길 16-16(빛가람동), 4층",
    post: 58217,
    x: 126.793045605705,
    y: 35.0238872539941,
  },
  {
    id: 94,
    dong: "빛가람동",
    name: "365 우리약국",
    address: "전라남도 나주시 전력로 2 (빛가람동)",
    post: 58217,
    x: 126.785384926139,
    y: 35.0215716490186,
  },
  {
    id: 95,
    dong: "빛가람동",
    name: "SM오피스 나주빛가점",
    address: "전라남도 나주시 전력로 6 (빛가람동)",
    post: 58217,
    x: 126.785382494816,
    y: 35.0219988979153,
  },
  {
    id: 96,
    dong: "빛가람동",
    name: "과일소담촌 나주혁신점",
    address: "전남 나주시 쌍산2길 19 1층",
    post: 58323,
    x: 126.793706842548,
    y: 35.0299252651667,
  },
  {
    id: 97,
    dong: "빛가람동",
    name: "금천농협빛가람점 하나로마트",
    address: "전라남도 나주시 중야2길 24 (빛가람동)",
    post: 58325,
    x: 126.785155095316,
    y: 35.0169444669357,
  },
  {
    id: 98,
    dong: "빛가람동",
    name: "빛가람 포도약국",
    address: "전라남도 나주시 중야2길 29 (빛가람동)",
    post: 58325,
    x: 126.784645691217,
    y: 35.0171245609915,
  },
  {
    id: 99,
    dong: "빛가람동",
    name: "산포농협 하나로마트 로컬푸드직매장",
    address: "전라남도 나주시 한빛로 171 (빛가람동)",
    post: 58323,
    x: 126.78926213065,
    y: 35.0288696869646,
  },
  {
    id: 100,
    dong: "빛가람동",
    name: "㈜그린골프 나주혁신점",
    address: "전라남도 나주시 상야4길 16-16 (빛가람동)",
    post: 58217,
    x: 126.793045605705,
    y: 35.0238872539941,
  },
  {
    id: 101,
    dong: "빛가람동",
    name: "주식회사 혁신에너지",
    address: "전라남도 나주시 그린로 116 (빛가람동)",
    post: 58329,
    x: 126.779672033277,
    y: 35.0119472169049,
  },
  {
    id: 102,
    dong: "빛가람동",
    name: "풀무원푸드",
    address: "전라남도 나주시 그린로 20(빛가람동)",
    post: 58327,
    x: 126.777107136951,
    y: 35.0203513157276,
  },
  {
    id: 103,
    dong: "빛가람동",
    name: "한살림광주소비자생활협동조합 나주빛가람매장",
    address: "전라남도 나주시 우정로 75(빛가람동), 상가동 143동 126호",
    post: 58217,
    x: 126.787163981006,
    y: 35.0239629828585,
  },
  {
    id: 104,
    dong: "빛가람동",
    name: "와이식자재마트나주점",
    address: "전라남도 나주시 빛가람로 838",
    post: 58330,
    x: 126.803395844867,
    y: 35.0213160649876,
  },
];

export const DongAffiliateList = [
  { name: "합계", index: 4268 },
  { name: "남평읍", index: 416 },
  { name: "세지면", index: 60 },
  { name: "왕곡면", index: 59 },
  { name: "반남면", index: 17 },
  { name: "공산면", index: 43 },
  { name: "동강면", index: 34 },
  { name: "다시면", index: 85 },
  { name: "문평면", index: 26 },
  { name: "노안면", index: 60 },
  { name: "금천면", index: 85 },
  { name: "산포면", index: 85 },
  { name: "다도면", index: 17 },
  { name: "봉황면", index: 62 },
  { name: "송월동", index: 193 },
  { name: "영강동", index: 53 },
  { name: "금남동", index: 296 },
  { name: "성북동", index: 554 },
  { name: "영산동", index: 74 },
  { name: "이창동", index: 332 },
  { name: "빛가람동", index: 1717 },
];

export const TypeAffiliateList = [
  { type: "합계", index: "4268" },
  { type: "소매업", index: "1213" },
  { type: "보건업", index: "150" },
  { type: "음식점업", index: "1628" },
  { type: "개인서비스업", index: "638" },
  { type: "교육서비스업", index: "332" },
  { type: "스포츠여가관련서비스업", index: "55" },
  { type: "숙박업", index: "25" },
  { type: "제조업", index: "161" },
  { type: "기타", index: "66" },
];
