import { useEffect } from "react";

const { kakao } = window;

const OutKakaoMap = ({ data }) => {
  useEffect(() => {
    const mapContainer = document.getElementById("map"),
      mapOption = {
        center: new kakao.maps.LatLng(35.0160601, 126.7107572),
        level: 8,
      };

    const map = new kakao.maps.Map(mapContainer, mapOption);

    // eslint-disable-next-line array-callback-return
    const positions = data.map((item) => {
      if (item.x !== 0 && item.y !== 0) {
        return {
          content: `<div style="width: auto; font-weight: bold; magin:1rem">${item.name}</div>`,
          latlng: new kakao.maps.LatLng(item.y, item.x),
          name: item.name,
        };
      }
    });

    positions.filter(Boolean).forEach((item) => {
      const marker = new kakao.maps.Marker({
        map: map, // 마커를 표시할 지도
        position: item.latlng, // 마커를 표시할 위치
        clickable: true,
      });

      let iwContent = `<div style="font-size:12px; padding:5px; width: 100%">${item.name}</div>`; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다

      // 인포윈도우를 생성합니다
      let infowindow = new kakao.maps.InfoWindow({
        content: iwContent,
        removable: true,
      });

      // 마커에 클릭이벤트를 등록합니다
      kakao.maps.event.addListener(marker, "click", function () {
        // 마커 위에 인포윈도우를 표시합니다
        infowindow.open(map, marker);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="map" style={{ width: "100%", height: "300px" }}></div>;
};

export default OutKakaoMap;
