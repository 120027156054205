import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../App.css";
import DispositionDetailBox from "../components/DispositionDetailBox";
import LoadingSpinner from "../components/LoadingSpinner";
import { ApiGetDispositionList } from "../service/Api";
import { DispositionColumns } from "../utils/TableColumnList";
import { TableCustomStyle } from "../utils/CustomStyle";

const DispositionPage = ({ isMobile }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const callDispositionList = async () => {
    const response = await ApiGetDispositionList();

    setData(() => [...response.data]);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    callDispositionList();
  }, []);

  return (
    <div className="App">
      <DataTable
        customStyles={TableCustomStyle(isMobile)}
        // responsive={!isMobile} // 화면이 작아질 경우 가로로 당길수있는지 여부
        columns={DispositionColumns(isMobile)} // 컬럼
        data={data} // 데이터
        defaultSortField="id" // 정렬로 사용할 key
        pagination // 페이지네이션
        subHeader // 서브헤더
        progressPending={isLoading} // loading boolean
        progressComponent={<LoadingSpinner />} // loading 컴포넌트
        expandableRows
        expandableRowExpanded={(row) => row.defaultExpanded}
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={DispositionDetailBox} // 클릭하면 나올 컴포넌트
        noDataComponent={
          <div style={{ margin: "10rem 0rem" }}>검색 결과가 없습니다.</div>
        }
        paginationComponentOptions={{
          rowsPerPageText: `${isMobile ? "표시" : "표시 개수"}`,
          rangeSeparatorText: "-",
          noRowsPerPage: false,
        }} // 하단 표시 옵션 (페이지)
      />
    </div>
  );
};

export default DispositionPage;
