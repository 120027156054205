import { getFoodLevel } from "./TableUtils.js";

export const Columns = (isMobile) => {
  if (isMobile) {
    return [
      {
        name: "가맹점명",
        selector: (row) => (
          <>
            {row.name} {getFoodLevel(row.level)}
          </>
        ),
        sortable: true,
      },
    ];
  }

  return [
    {
      name: "가맹점명",
      selector: (row) => (
        <>
          {row.name} {getFoodLevel(row.level)}
        </>
      ),
      sortable: true,
    },
    {
      name: "읍면동",
      selector: (row) => row.dong,
      sortable: true,
    },
    {
      name: "가맹점 유형",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "주소",
      selector: (row) => row.address1,
      sortable: true,
    },
    {
      name: "상세 주소",
      selector: (row) => row.address2,
      sortable: true,
    },
  ];
};

export const OutColumns = (isMobile) => {
  if (isMobile) {
    return [
      {
        name: "가맹점명",
        selector: (row) => row.name,
        sortable: true,
      },
    ];
  }

  return [
    {
      name: "가맹점명",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "소재지",
      selector: (row) => row.dong,
      sortable: true,
    },
    {
      name: "주소",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "우편번호",
      selector: (row) => row.post,
      sortable: true,
    },
  ];
};

export const DispositionColumns = (isMobile) => {
  if (isMobile) {
    return [
      {
        name: "가맹점명",
        selector: (row) => row.prcscitypointBsshnm,
        sortable: true,
      },
    ];
  }

  return [
    {
      name: "가맹점명",
      selector: (row) => row.prcscitypointBsshnm,
      sortable: true,
    },
    {
      name: "기준 날짜",
      selector: (row) => `${row.dspsDcsndt ? row.dspsDcsndt : row.dspsBgndt}`,
      sortable: true,
    },
    {
      name: "위반 내용",
      selector: (row) => row.viltcn,
      sortable: true,
    },

    {
      name: "처리 내용",
      selector: (row) => row.dspscn,
      sortable: true,
    },
  ];
};
