import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function PopoverBox({ popTitle, text }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Typography
        sx={{ fontSize: "0.8rem", mb: 1.5 }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        {popTitle}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{text}</Typography>
      </Popover>
    </div>
  );
}
