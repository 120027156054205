import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: `http://api.devsong.co.kr`,
});

// instance.interceptors.request.use(
//   (config) => {
//     // const { token } = persistor.getState().Auth;
//     const baseHeaders = { ...config.headers };
//     // const headers = token ? { ...baseHeaders, Authorization: `Bearer ${token}` } : { ...baseHeaders };
//     const headers = { ...baseHeaders };
//     return { ...config, headers };
//   },
//   (error) => Promise.reject(error)
// );

const callApi = async (config) => {
  try {
    return await instance({
      ...config,
    });
  } catch (error) {
    // if (error.response?.status !== 200) {
    // alert("잠시후 다시 시도해주세요.");
    // }
    return error.response;
  }
};

/* 가맹점 리스트 가져오기 */
export const ApiCallNajuStore = () =>
  callApi({
    url: "/naju/affiliate",
  });

/* 행정처분내역 리스트 가져오기 */
export const ApiGetDispositionList = () =>
  callApi({
    url: "/naju/getDispositionList",
  });

/* 로그 기록 */
export const ApiNajuSetLog = (userIp) =>
  callApi({
    url: `/naju/setLog?userIp=${userIp}`,
    method: "GET",
  });

/* 서버, DB 연걸 테스트 */
export const ApiConnectionTest = () =>
  callApi({
    url: "/test/database",
  });
