export const TableCustomStyle = (isMobile) => {
  return {
    rows: {
      style: {
        minHeight: "3.7rem", // override the row height
        "&:hover": {
          backgroundColor: "aliceblue",
        },
      },
    },
    expanderRow: {
      style: {
        backgroundColor: "rgb(249 249 249)",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
        justifyContent: `${isMobile ? "center" : "unset"}`,
      },
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      },
    },
  };
};
