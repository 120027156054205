import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DongAffiliateList, TypeAffiliateList } from "../utils/NajuDataList";

export default function TableBox({ type }) {
  return (
    <>
      {type === "읍면동" ? (
        <TableContainer sx={{ maxHeight: 400, maxWidth: 400 }}>
          <Table sx={{ maxWidth: 400 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#edf6ff" }}>
                <TableCell sx={{ fontWeight: "bold" }}>읍면동</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  개수
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DongAffiliateList.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row.name === "합계" ? (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: "bold" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        {row.index}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.index}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <TableContainer sx={{ maxHeight: 400, maxWidth: 400 }}>
            <Table sx={{ maxWidth: 400 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#edf6ff" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>유형</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    개수
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TypeAffiliateList.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {row.type === "합계" ? (
                      <>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "bold" }}
                        >
                          {row.type}
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {row.index}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell component="th" scope="row">
                          {row.type}
                        </TableCell>
                        <TableCell align="right">{row.index}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
