import React, { useEffect, useState } from "react";
import "./App.css";
import TitleBox from "./components/TitleBox";
import MainPage from "./pages/MainPage";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 모바일 여부
  const [title, setTitle] = useState("나주사랑상품권 가맹점 조회");

  /* 모바일 화면 감지 */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="topBox">
      <div className="contentBox">
        <TitleBox isMobile={isMobile} title={title} />
        <MainPage isMobile={isMobile} setTitle={setTitle} />
      </div>
    </div>
  );
}

export default App;
