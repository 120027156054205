import { Button, TextField } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "../App.css";

const FilterComponent = ({ filterText, onFilter, onClear, color = "info" }) => (
  <div className="searchBox">
    <TextField
      className="searchInput"
      type="text"
      placeholder="가맹점을 입력하세요."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <Button
      className="resetButton"
      variant="contained"
      size="large"
      color={color} // 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
      sx={{ ml: 0.5 }}
      onClick={onClear}
    >
      <RestartAltIcon className="resetButton" onClick={onClear} />
    </Button>
  </div>
);

export default FilterComponent;
