import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import TableBox from "../components/TableBox";

export default function GuidePage({ isMobile }) {
  return (
    <Container sx={{ mt: 5, mb: 10 }}>
      {/* ================================ 데이터 ================================ */}
      <Typography sx={{ fontSize: "1rem", mb: 1, fontWeight: "bold" }}>
        ■ 데이터 기준
      </Typography>
      <Typography sx={{ ml: 1.5 }}>가맹점 데이터(4984) : 2024.02.05</Typography>
      <Typography sx={{ ml: 1.5 }}>
        취소 가맹점 데이터(104개) : 2023.08.10
      </Typography>
      {/* ======================================================================== */}

      <br style={{ marginBottom: "2rem" }} />

      {/* ================================ 가맹점 ================================ */}
      <Typography sx={{ fontSize: "1rem", mb: 1, fontWeight: "bold" }}>
        ■ 가맹점 현황
      </Typography>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "space-around",
        }}
      >
        <TableBox type="읍면동" />
        <TableBox />
      </div>

      {/* ======================================================================== */}

      <br style={{ marginBottom: "3rem" }} />

      {/* ============================ 취소 가맹점 =============================== */}
      <Typography sx={{ fontSize: "1rem", mt: 2, mb: 1, fontWeight: "bold" }}>
        ■ 취소 가맹점
      </Typography>
      <Typography sx={{ p: 2 }}>
        행정안전부의 [2023년 지역사랑상품권 발행지원 사업 종합지침]에 따른
        가맹점 등록기준 부적합
        <span style={{ color: "red" }}>(연 매출액 30억 초과)</span>으로
        <br />
        나주사랑상품권 가맹점 등록취소가 확정된 대상입니다.
      </Typography>
      {/* ======================================================================== */}

      <br style={{ marginBottom: "1.5rem" }} />

      {/* ======================== 행정 처분내역 ============================ */}
      <Typography sx={{ fontSize: "1rem", mb: 1, fontWeight: "bold" }}>
        ■ 행정처분 내역
      </Typography>
      <Typography sx={{ ml: 1.5 }}>
        데이터 출처 : 나주시청, 식품의약품안전처
      </Typography>
      {/* ======================================================================== */}

      <br style={{ marginBottom: "1.5rem" }} />

      {/* ======================================================================== */}

      <Typography sx={{ mt: 2, textAlign: "end" }}>
        문의 : rlathdwjd123@naver.com
      </Typography>
      <Typography sx={{ mt: 1, textAlign: "end" }} component="div">
        <Button
          variant="contained"
          href="https://www.naju.go.kr/www/field_info/economy/gift/naju_gift"
        >
          나주시 홈페이지 이동
        </Button>
      </Typography>

      {/* ======================================================================== */}
    </Container>
  );
}
