import { Avatar, Button } from "@mui/material";
import { getSearchKeword } from "../utils/TableUtils.js";

const MapPageMoveButton = ({ data, imageUrl }) => {
  const btnUrl = imageUrl.includes("kakao")
    ? `https://map.kakao.com/link/search/${getSearchKeword(
        data.name ? data.name : data.prcscitypointBsshnm
      )}`
    : `https://map.naver.com/v5/search/${getSearchKeword(
        data.name ? data.name : data.prcscitypointBsshnm
      )}`;

  return (
    <Button size="small" href={btnUrl}>
      <Avatar alt="icon" src={imageUrl} />
    </Button>
  );
};

export default MapPageMoveButton;
