import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "../App.css";
import DetailBox from "../components/DetailBox";
import LoadingSpinner from "../components/LoadingSpinner";
import FilterComponent from "../components/SearchBox";
import { ApiCallNajuStore } from "../service/Api";
import { OutColumns } from "../utils/TableColumnList";
import { TableCustomStyle } from "../utils/CustomStyle";
import { Button, Popover, Typography } from "@mui/material";
import { OutData } from "../utils/NajuDataList";
import OutKakaoMap from "../components/OutKakaoMap";

function OutPage({ isMobile }) {
  const [data, setData] = useState(OutData); // 원본데이터
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filteredItems, setFilteredItems] = useState(data); // 검색데이터

  /* ---------------------------------------------------------------------- */

  const resetEvent = useCallback(() => {
    setFilterText("");
    setResetPaginationToggle((state) => !state);
    setFilteredItems(data);
  }, [data]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
        }}
        onClear={() => {
          resetEvent();
        }}
        filterText={filterText}
        color="error"
      />
    );
  }, [filterText, resetEvent]);

  // eslint-disable-next-line no-unused-vars
  const callNajuList = async () => {
    const response = await ApiCallNajuStore();

    setData(() => [...response.data]);
    setIsLoading(false);
  };

  // 검색이벤트
  useEffect(() => {
    setFilteredItems([
      ...data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      ),
    ]);
  }, [filterText, data]);

  return (
    <div className="App">
      <OutKakaoMap data={data} />

      {isMobile ? (
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          <Button
            aria-describedby={id}
            variant="outlined"
            onClick={handleClick}
            color="inherit"
            sx={{ color: "gray" }}
          >
            취소 가맹점이란?
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>
              행정안전부의 [2023년 지역사랑상품권 발행지원 사업 종합지침]에 따른
              가맹점 등록기준 부적합
              <span style={{ color: "red" }}>(연 매출액 30억 초과)</span>으로
              나주사랑상품권 가맹점 등록취소가 확정된 대상입니다.
            </Typography>
          </Popover>
        </div>
      ) : (
        <>
          <div
            style={{
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            <div>
              행정안전부의 [2023년 지역사랑상품권 발행지원 사업 종합지침]에 따른
              가맹점 등록기준 부적합
              <span style={{ color: "red" }}>(연 매출액 30억 초과)</span>으로
              나주사랑상품권 가맹점 등록취소가 확정된 대상입니다.
            </div>
          </div>
        </>
      )}

      <DataTable
        customStyles={TableCustomStyle(isMobile)}
        // responsive={!isMobile} // 화면이 작아질 경우 가로로 당길수있는지 여부
        columns={OutColumns(isMobile)} // 컬럼
        data={filteredItems} // 데이터
        defaultSortField="id" // 정렬로 사용할 key
        pagination // 페이지네이션
        subHeader // 서브헤더
        paginationResetDefaultPage={resetPaginationToggle}
        subHeaderComponent={subHeaderComponentMemo} // 서브헤더 컴포넌트
        progressPending={isLoading} // loading boolean
        progressComponent={<LoadingSpinner />} // loading 컴포넌트
        expandableRows
        expandableRowExpanded={(row) => row.defaultExpanded}
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={DetailBox} // 클릭하면 나올 컴포넌트
        noDataComponent={
          <div style={{ margin: "10rem 0rem" }}>검색 결과가 없습니다.</div>
        }
        paginationComponentOptions={{
          rowsPerPageText: `${isMobile ? "표시" : "표시 개수"}`,
          rangeSeparatorText: "-",
          noRowsPerPage: false,
        }} // 하단 표시 옵션 (페이지)
      />
    </div>
  );
}

export default OutPage;
