import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "../App.css";
import kakaoIcon from "../assets/kakao.svg";
import naverIcon from "../assets/naver.svg";
import ImgBox from "./MapPageMoveButton";

const DispositionDetailBox = ({ data }) => {
  return (
    <>
      <div className="detailBox">
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
              {data.prcscitypointBsshnm}
            </Typography>
            <Typography
              sx={{ fontSize: "0.8rem", mb: 1.5 }}
              color="text.secondary"
            >
              {data.indutyCdNm} (대표자 : {data.prsdntNm})
            </Typography>

            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              적발일 / 처분일 : {data.dspsDcsndt}
            </Typography>

            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              {data.dspsBgndt !== "-" &&
              data.dspsBgndt !== "" &&
              data.dspsEnddt !== "-" &&
              data.dspsEnddt !== ""
                ? `영업정지 기간 : ${data.dspsBgndt} ~ ${data.dspsEnddt}`
                : ""}
            </Typography>

            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              위반 내용 : {data.viltcn}
            </Typography>

            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              처리 내용 : {data.dspscn}
            </Typography>

            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              {data.lawordCdNm ? `관련 법안 : ${data.lawordCdNm}` : ""}
            </Typography>
            <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
              주소 : {data.addr}
            </Typography>
          </CardContent>
          {!data.viltcn.includes("폐업") ? (
            <CardActions>
              <ImgBox data={data} imageUrl={naverIcon} />
              <ImgBox data={data} imageUrl={kakaoIcon} />
            </CardActions>
          ) : (
            ""
          )}
        </Card>
      </div>
    </>
  );
};

export default DispositionDetailBox;
