import React from "react";

const TitleBox = ({ isMobile, title }) => (
  <>
    {isMobile ? (
      <div className="topContent" style={{ display: "block" }}>
        <h3 className="title">{title}</h3>
      </div>
    ) : (
      <div className="topContent">
        <h2 className="title">{title}</h2>
      </div>
    )}
  </>
);

export default TitleBox;
