import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import "../App.css";
import kakaoIcon from "../assets/kakao.svg";
import naverIcon from "../assets/naver.svg";
import MapPageMoveButton from "./MapPageMoveButton";
import KakaoMap from "./KakaoMap";
import PopoverBox from "./PopoverBox";

const DetailBox = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 모바일 여부

  // const CreateMap = useCallback(() => {
  //   return <KakaoMap data={data} />;
  // }, [data]);

  /* 모바일 화면 감지 */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {data.address1 ? (
        <div
          className="detailBox"
          // style={{ margin: `${isMobile ? "2rem" : "5rem"}}` }}
        >
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <KakaoMap data={data} />
              <Typography
                sx={{ fontSize: "0.7rem", color: "#b1b1b1", textAlign: " end" }}
              >
                (해당 마커는 주소상의 위치로, 실제와 다를 수 있음)
              </Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold", mt: 2 }}>
                {data.name}
              </Typography>
              <Typography
                sx={{ fontSize: "0.8rem", mb: 1.5 }}
                color="text.secondary"
              >
                {data.dong} ({data.type})
              </Typography>

              <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
                {data.level ? (
                  <PopoverBox
                    popTitle={`위생등급 : ${data.level} (${data.asgnFrom} ~ ${data.asgnTo})`}
                    text="식품의약품안전처에서 주관한 식품접객업,위생등급,음식점 위생등급 자료입니다."
                  />
                ) : (
                  ""
                )}
              </Typography>
              <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
                {isMobile ? (
                  <>
                    {data.address1}
                    <br />
                    {data.address2 && data.address2 !== "-"
                      ? data.address2
                      : ""}
                  </>
                ) : (
                  <>
                    {data.address1}
                    {" , "}
                    {data.address2 && data.address2 !== "-"
                      ? data.address2
                      : ""}
                  </>
                )}
              </Typography>
            </CardContent>
            <CardActions>
              <MapPageMoveButton data={data} imageUrl={naverIcon} />
              <MapPageMoveButton data={data} imageUrl={kakaoIcon} />
            </CardActions>
          </Card>
        </div>
      ) : (
        <>
          <div
            className="detailBox"
            // style={{ margin: `${isMobile ? "2rem" : "5rem"}}` }}
          >
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {data.name}
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", mb: 1.5 }}
                  color="text.secondary"
                >
                  {data.post}
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", mb: 1.5 }}>
                  {data.address}
                </Typography>
              </CardContent>
              <CardActions>
                <MapPageMoveButton data={data} imageUrl={naverIcon} />
                <MapPageMoveButton data={data} imageUrl={kakaoIcon} />
              </CardActions>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default DetailBox;
